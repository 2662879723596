import '../styles/css/qcify-styles.css';
//import '../styles/globals.css';
import 'bulma-tooltip/dist/css/bulma-tooltip.min.css';
import App, { AppContext, AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import Head from 'next/head';
import '@fortawesome/fontawesome-free/css/all.css';
import { EnvironmentProvider } from '@qcify/ui';


type MyAppProps = AppProps & {
  baseUrl: string,
  devMode: boolean,
}

export default function MyApp({ Component, pageProps: { session, ...pageProps }, baseUrl }: MyAppProps) {
    return (
        <SessionProvider session={session}>
            <EnvironmentProvider envCheckEndpoint="/api/environment/get">
                <Head>
                    <title>AIP Management tool</title>
                </Head>
                <Component {...pageProps} />
            </EnvironmentProvider>
        </SessionProvider>
    );
}

MyApp.getInitialProps = async (context: AppContext) => {
    const appProps: any = await App.getInitialProps(context);
    return { ...appProps, baseUrl: process.env.BASE_URL };
};